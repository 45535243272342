import React from "react"

import Layout from "../../components/layout"
import HtmlCss from "../../components/screens/Skill/HtmlCss"
import { Helmet } from "react-helmet"

const HtmlCssPage = () => (
  <Layout pageInfo={{ pageName: "Html/Css" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Html/Css</title>
        <meta name="description" content="Html Css" />
    </Helmet>
    <HtmlCss />
  </Layout>
)

export default HtmlCssPage
